<template>
  <v-container>
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

    <h3>Registrovaní uživatelé <span v-if="!loading">({{ filteredAccounts.length }})</span> :</h3>

    <v-row class="mt-1">
      <v-col cols="12" md="2">
        <v-text-field
            v-model="filter_last_name"
            label="Příjmení"
            variant="outlined"
            hide-details
            density="compact"
            @keyup.enter="fetchAccounts"
        />
      </v-col>
      <v-col cols="12" md="1">
        <v-text-field
            v-model="filter_index"
            label="Index"
            variant="outlined"
            hide-details
            density="compact"
            @keyup.enter="fetchAccounts"
        />
      </v-col>

      <v-col cols="12" md="1">
        <v-select
            v-model="filter_club"
            :items="clubShortcuts"
            label="oddíl"
            variant="outlined"
            hide-details
            density="compact"
            @keyup.enter="fetchAccounts"
        />
      </v-col>
      <!--      <v-col cols="12" md="3">-->
      <!--        <v-text-field-->
      <!--            v-model="filter_gender"-->
      <!--            label="Pohlaví"-->
      <!--            variant="outlined"-->
      <!--            hide-details-->
      <!--            single-line-->
      <!--        />-->
      <!--      </v-col>-->
      <v-col cols="12" md="1">
        <v-text-field
            v-model="filter_min_year"
            label="Ročník od"
            variant="outlined"
            hide-details
            density="compact"
        />
      </v-col>
      <v-col cols="12" md="1">
        <v-text-field
            v-model="filter_max_year"
            label="Ročník do"
            variant="outlined"
            hide-details
            density="compact"
        />
      </v-col>
      <!-- <v-col cols="12" md="3">
        <v-text-field
            v-model="filter_referee"
            label="Rozhodčí"
            variant="outlined"
            hide-details
            single-line
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            v-model="filter_coach"
            label="Trenér"
            variant="outlined"
            hide-details
            single-line
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            v-model="filter_vt"
            label="VT"
            variant="outlined"
            hide-details
            single-line
        />
      </v-col> -->
      <v-col>
        <v-btn color="primary" @click="fetchAccounts" :disabled="!valid">Filtrovat</v-btn>
      </v-col>

    </v-row>


    <v-row>
      <v-col>
        <v-data-table
            density="comfortable"
            :headers="accountHeaders"
            :items="filteredAccounts"
            item-key="id"
            :items-per-page="500"
            :sort-by="sortBy"
            :loading="loading"
            loading-text="Načítám data..."
        >
          <!-- <template v-slot:loading>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </template> -->

          <template v-slot:[`item.last_name`]="{ item }">
            <v-tooltip location="top center" origin="auto"
                       :text="'Poslední aktivita: ' + $formatDateTime(item.last_activity)">
              <template v-slot:activator="{ props }">
                <router-link v-bind="props" :to="'/ucet/' + item.id">{{ item.last_name }}</router-link>
              </template>
            </v-tooltip>
          </template>
          <!-- <template v-slot:[`item.gender`]="{ item }">
            {{ sexOptions.formatGender(item.gender) }}
          </template> -->

          <template v-slot:[`item.referee`]="{ item }">
            <v-tooltip location="top center" origin="auto" v-if="item.referee">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.referee?.lvl }}. třída</span>
              </template>
              <div>Platnost: {{
                  $formatDate(item.referee.valid_from) + " - " + $formatDate(item.referee.valid_to)
                }}
              </div>
              <div>Poznámka: {{ item.referee.description }}</div>
            </v-tooltip>
          </template>

          <template v-slot:[`item.coach`]="{ item }">
            <v-tooltip location="top center" origin="auto" v-if="item.coach">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.coach?.lvl }}. třída</span>
              </template>
              <div>Platnost: {{ $formatDate(item.coach.valid_from) + " - " + $formatDate(item.coach.valid_to) }}</div>
              <div>Poznámka: {{ item.coach.description }}</div>
            </v-tooltip>
          </template>

          <!-- <template v-slot:[`item.groups`]="{ item }">
            {{ formatGroups(item.groups) }}
          </template> -->

          <template v-slot:[`item.last_activity`]="{ item }">
            {{ $formatDateTime(item.last_activity) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }" v-if="manager">
            <v-list-item-action>
              <!-- <v-icon @click="editFunction(item)" color="success" size="33" class="mr-2" title="editovat účet">mdi-account-edit</v-icon> -->
              <v-icon @click="editRoles(item)" color="blue" size="33" class="mr-2"
                      title="editovat rozhodčího nebo trenéra">mdi-account-hard-hat
              </v-icon>
            </v-list-item-action>
          </template>


          <template v-slot:no-data>
            <v-alert type="info" class="ma-4">
              Žádná data neodpovídají vyhledávání
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

  <RoleDialog
      v-model="roleDialogOpen"
      :account-id="selectedAccountId"
      @close-dialog="roleDialogOpen = false"/>

  <ScrollTopButton />

</template>


<script setup>
import {ref, onMounted, computed} from 'vue';
import apiClient from '/api.js';
import {useAuthStore} from '/auth.js';
import RoleDialog from "@/components/shared/RoleDialog.vue";
import ScrollTopButton from '@/components/shared/ScrollTopButton.vue';

//import RoleTable from "@/components/shared/RoleTable.vue";


const authStore = useAuthStore();
const valid = ref(true);
const accounts = ref([]);
const loading = ref(true);  // Přidáme proměnnou loading
const accountsCount = ref(0);

const filter_last_name = ref("")
const filter_index = ref("")
const filter_club = ref("")
// const filter_gender = ref(false)
const filter_min_year = ref("")
const filter_max_year = ref("")
// const filter_referee = ref("")
// const filter_coach = ref("")
// const filter_vt = ref("")

const manager = authStore.roles.includes('manager');

const accountHeaders = [
  // { title: 'ID', value: 'id', sortable: true, align: 'start' },
  {title: 'Příjmení', value: 'last_name', sortable: true, align: 'start'},
  {title: 'Jméno', value: 'first_name', sortable: true, align: 'start'},
  {title: 'Index', value: 'index', sortable: true, align: 'start'},
  // {title: 'Pohlaví', value: 'gender', sortable: true, align: 'start'},
  // {title: 'Rok narození', value: 'birth_year', sortable: true, align: 'start'},
  {title: 'Rozhodčí', value: 'referee', sortable: true, align: 'start'},
  {title: 'Trenér', value: 'coach', sortable: true, align: 'start'},
  // {title: 'VT', value: 'license', sortable: true, align: 'start'},
  // {title: 'Pravomoce', value: 'groups', sortable: false, align: 'start', maxWidth: 130},
  ...(manager ? [{title: 'Akce', value: 'actions', sortable: false, width: '120'}] : []), // Přidat sloupec jen pro 'manager'
];

const sortBy = [{key: 'last_name', order: 'asc'}]; // Klíč pro řazení


// // alternativy práv k hodnotám z api
// const groupNames = {
//   organizer: "organizátor",
//   club_manager: "správce oddílu",
//   manager: "manažer"
// };

// const sexOptions = {
//   options: [{ title: 'Muž', value: 'M' }, { title: 'Žena', value: 'F' }],
//   formatGender(gender) {
//     const option = this.options.find(option => option.value === gender);
//     return option ? option.title : gender;
//   }
// };


const filteredAccounts = computed(() => {  // Data filtrovaná podle podmínek
  return accounts.value.filter(account => {
    const minYear = parseInt(filter_min_year.value) || null;
    const maxYear = parseInt(filter_max_year.value) || null;

    // Pokud není nastaven žádný rok, zobrazíme všechny
    if (!minYear && !maxYear) return true;

    // Aplikace filtrů
    if (minYear && account.birth_year < minYear) return false;
    if (maxYear && account.birth_year > maxYear) return false;

    return true;
  });
});


// // přidání alternativ do groups
// const formatGroups = (groups) => {
//   return groups.map(group => groupNames[group] || group).join(', ');
// };

const clubShortcuts = ref([]);

const fetchAccounts = async () => {
  try {
    const params = {};

    // Přidáme příjmení do parametrů, pokud je vyplněné
    if (filter_last_name.value.trim()) {
      params.last_name = filter_last_name.value.trim();
    }

    // Přidáme zkratku klubu do parametrů, pokud je vyplněná
    if (filter_club.value.trim()) {
      params.club_shortcut = filter_club.value.trim();
    }
    // Přidáme index do parametrů, pokud je vyplněný
    if (filter_index.value.trim()) {
      params.index = filter_index.value.trim();
    }


    // Odešleme GET požadavek s parametry
    const response = await apiClient.get('/members_all/', {params});

    accounts.value = response.data.all_members
    clubShortcuts.value = ["", ...response.data.club_shortcuts];
    accountsCount.value = accounts.value.length;
  } catch (error) {
    console.error('Error fetching accounts:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }
};


onMounted(() => {
  fetchAccounts();
});


const roleDialogOpen = ref(false);

// aktivace dialogu Funkce
const selectedAccountId = ref(-1);

const editRoles = (item) => {
  console.log(item)
  selectedAccountId.value = item.id;
  roleDialogOpen.value = true;
};

</script>


<style scoped>

:deep() .v-table .v-table__wrapper > table > thead > tr > th:not(:last-child) {
  border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child), .v-table .v-table__wrapper > table > tbody > tr > th:not(:last-child) {
  border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}


/* :deep() table th + th { border-left:10px solid #dddddd; }
:deep() table td + td { border-left:10px solid #dddddd; } */
</style>