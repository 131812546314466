<template>
  <v-container>
    <v-card class="mb-2">
      <v-card-title>{{ account.first_name }} {{ account.last_name }} <CountryFlags :countryCode="account.country" /> {{ account.index }} </v-card-title>
      <v-card-text>
        <div v-if="isLoggedIn"><b>ID:</b> {{ account.id }}</div>
        <div v-if="showEmail"><b>Email:</b> {{ account.email }}</div>
        <div v-if="account"><b>Poslední aktivita:</b> {{ $formatDateTime(account.last_activity) }}</div>
        <div v-if="isLoggedIn && account.groups && account.groups.length > 0">
          <b>Pravomoce: </b>
          <span v-for="(group, index) in account.groups" :key="group">
            {{
              group === 'organizer' ? 'organizátor' :
                  group === 'club_manager' ? 'vedoucí oddílu' :
                      group === 'manager' ? 'manažer' :
                          group
            }}<span v-if="index < account.groups.length - 1">, </span>
          </span>
        </div>

      </v-card-text>
    </v-card>
    <v-expansion-panels variant="accordion">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
          Přehled tříd
          <v-progress-circular v-if="loadingExperience" class="ml-3" indeterminate
                               color="primary"></v-progress-circular>

        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <RoleTable :account-id="accountId"/>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title>
          <v-icon class="mr-2">mdi-playlist-check</v-icon>
          Přehled praxe na závodech
          <v-progress-circular v-if="loadingExperience" class="ml-3" indeterminatecolor="primary" />
          <span class="ml-3 text-h6" v-else>({{ experience.length }})</span>

        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-data-table
              density="compact"
              hide-default-footer="true"
              :headers="experienceHeaders"
              :items="experience"
              class="elevation-1"
              :items-per-page="-1"
              :sort-by="experienceSortBy"
              :loading="loadingExperience"
              loading-text="Načítám data..."
              no-data-text="Nenalezeny žádné záznamy o praxi"
          >
            <template v-slot:[`item.race_date`]="{ item }">
              {{ $formatDate(item.race_date) }}
            </template>
            <template v-slot:[`item.race_name`]="{ item }">
              {{ item.race_name }}
            </template>
            <template v-slot:[`item.race_level`]="{ item }">
              <span style="white-space: nowrap;">{{ item.race_level }}</span>
            </template>
            <template v-slot:[`item.position`]="{ item }">
              <span style="white-space: nowrap;">{{ item.position }}</span>
            </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel readonly="readonly" expand-icon="" class="bg-orange-lighten-4">
        <v-expansion-panel-title>
          <v-icon class="mr-2">mdi-trophy</v-icon>
          Účast na soutěžích
        </v-expansion-panel-title>
        <v-data-table 
          density="compact" 
          :headers="headers" 
          :items="entries" 
          class="elevation-1" 
          :items-per-page="50" 
          :sort-by="sortBy" 
          :loading="loading" 
          loading-text="Načítám data..."
        >
          <template v-slot:item="{ item }">
            <tr :class="{ 'upcoming': isFutureDate(item.race_date), 'canceled': item.event_canceled }">
              <td>
                {{
                  item.is_event ? $formatDate(item.race_date) + "&ndash;" + $formatDate(item.event_date_end) : $formatDate(item.race_date)
                }}
              </td>
              <td>
                <router-link :to="'/soutez/' + item.event_id">{{ item.event_name }}</router-link>
              </td>
              <td>{{ item.race_name }}</td>
              <td>{{ item.category_name }}</td>
              <td><span v-if="!item.is_event">{{ item.place ?? '---' }}</span></td>
            </tr>
          </template>

          <template v-slot:no-data>
            {{ account.first_name }} ještě nikde nebyl<span v-if="account.gender == 'F'">a</span> :(
          </template>

        </v-data-table>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue';
import apiClient from '/api.js';
import {useRoute} from 'vue-router';
import {useAuthStore} from '/auth.js';
import RoleTable from "@/components/shared/RoleTable.vue";

import CountryFlags from '@/components/shared/CountryFlags.vue';


const authStore = useAuthStore();
const isLoggedIn = ref(authStore.isLoggedIn);
const route = useRoute();
const accountId = route.params.id; // Získání ID uživatele z URLconst account = ref({});
const entries = ref([]);
const account = ref({});
const sortBy = [{key: 'race_date', order: 'desc'}]; // Klíč pro řazení
const headers = [
  {title: 'Datum', key: 'event_date_start? event_date_start : race_date', align: 'left', sortable: true},
  {title: 'Název soutěže', key: 'event_name', align: 'left', sortable: true},
  {title: 'Název etapy', key: 'race_name', align: 'left', sortable: true},
  {title: 'Kategorie', key: 'category_name', align: 'left', sortable: false},
  {title: 'Místo', key: 'place', align: 'left', sortable: false},
];

const loading = ref(true);

const showEmail = computed(() => authStore.isLoggedIn && account.value.allow_email); // pravidlo pro viditelnost emailu

const isFutureDate = (dateString) => {
  const currentDate = new Date();
  const raceDate = new Date(dateString);
  return raceDate > currentDate;
};

// Načítání údajů účtu
const loadAccountData = async (accountId) => {
  try {
    const response = await apiClient.get(`account/?id=${accountId}`);
    account.value = response.data[0];
  } catch (error) {
    console.error("There was an error fetching the account data:", error);
  }
};

// Načítání entries uživatele
const loadUserEntries = async (accountId) => {
  try {
    const response = await apiClient.get(`account/${accountId}/entries`);
    entries.value = response.data
  } catch (error) {
    console.error('Chyba při načítání entries:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }

};


const experienceHeaders = [
  {title: 'Datum', key: 'race_date', align: 'left', sortable: true},
  {title: 'Závod', key: 'race_name', align: 'left', sortable: true},
  {title: 'Úroveň', key: 'race_level', align: 'left', sortable: false},
  {title: 'Pozice', key: 'position', align: 'left', sortable: false},
];

const experienceSortBy = [{key: 'race_date', order: 'desc'}]; // Klíč pro řazení

const loadingExperience = ref(true);
const experience = ref([]);

// Načítání entries uživatele
const loadUserExperience = async (accountId) => {
  try {
    const response = await apiClient.get(`account/${accountId}/experience`);
    experience.value = response.data
  } catch (error) {
    console.error('Chyba při načítání praxe:', error);
  } finally {
    loadingExperience.value = false;  // Nastavíme loading na false po načtení dat
  }

};


// Sledujeme změny v ID uživatele a načítáme nová data při změně URL
watch(
    () => route.params.id, // Sledujeme změny route.params.id
    (newId) => {
      loadAccountData(newId); // Zavoláme funkci s novým ID
      loadUserEntries(newId);
      loadUserExperience(newId);
    }
);

onMounted(() => {
  loadAccountData(accountId);
  loadUserEntries(accountId);
  loadUserExperience(accountId);
});


</script>


<style scoped>
.upcoming {
  background-color: rgba(34, 212, 93, 0.15) !important;
}

.canceled {
  text-decoration: line-through;
}

:deep() .v-expansion-panel-text__wrapper {
  padding: 0px;
}

</style>
