<template>
    <v-btn
      v-show="showScrollTopButton"
      @click="scrollToTop"
      class="scroll-top-btn"
      color="primary"
      icon
      large
      elevation="10"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from "vue";
  
  const showScrollTopButton = ref(false);
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  const onScroll = () => {
    showScrollTopButton.value = window.scrollY > 300;
  };
  
  onMounted(() => {
    window.addEventListener("scroll", onScroll);
  });
  
  onUnmounted(() => {
    window.removeEventListener("scroll", onScroll);
  });
  </script>
  
  <style scoped>
  .scroll-top-btn {
    position: fixed;
    bottom: 35px;
    right: 25px;
    z-index: 9999 !important;
  }
  </style>
  