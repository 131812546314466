<template>
    <v-container>
      <v-row class="mb-3">
        <!-- Výběr závodu -->
        <v-col cols="12" md="8">
          <v-select
            :items="raceOptions"
            v-model="selectedRace"
            density="comfortable"
            label="Vyber závod"
            hide-details="true"
            item-title="label"
            item-value="value"
            no-data-text="Nenalezeny žádné závody v tomto roce"
          >
          </v-select>
        </v-col>
        <v-spacer />
        <!-- Výběr roku -->
        <v-col cols="12" md="2">
          <SelectYear v-model="selectedYear" />
        </v-col>
      </v-row>
      <!-- Tabulka výsledků -->
      <ResultsTable v-if="selectedRace" :raceId="selectedRace.id" />
    </v-container>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import ResultsTable from '@/components/shared/ResultsTable.vue';
  import SelectYear from '@/components/shared/SelectYear.vue';
  import apiClient from '/api.js';
  import { useDateTimeFormat } from '@/composables/useDateTimeFormat';


  const { formatDate } = useDateTimeFormat();
  const selectedYear = ref(null); // Vybraný rok
  const races = ref([]); // Načtené závody
  const selectedRace = ref(null); // Vybraný závod
  
// Možnosti závodů pro select
  const raceOptions = ref([]);
    
  // Funkce pro načtení závodů z API
  const fetchRaces = async (year) => {
    if (!year) return;
  
    try {
      const response = await apiClient.get('race/', {
        params: { year },
      });
      races.value = response.data.sort((a, b) => new Date(b.race_date) - new Date(a.race_date));
  
      // Vytvoření možností pro select závodů
      raceOptions.value = races.value.map((race) => ({
        label: `${formatDate(race.race_date)} - ${race.event_name} (${race.race_name})`, // Text zobrazený v selectu
        value: race, // Celý objekt závodu jako hodnota
      }));
  
      // Automaticky vyber první závod podle data nebo poslední závod v seznamu
      if (raceOptions.value.length > 0) {
        const today = new Date().toISOString().split('T')[0];
        const racesToday = raceOptions.value.filter(
          (option) => option.value.race_date === today
        );

        if (racesToday.length > 0) {
          // Vyber poslední závod, pokud existuje více závodů pro dnešek
          selectedRace.value = racesToday[racesToday.length - 1].value;
        } else {
          // Pokud neexistuje závod pro dnešek, vyber první závod v seznamu
          selectedRace.value = raceOptions.value[0].value;
        }
      } else {
        selectedRace.value = null;
      }
    } catch (error) {
      console.error('Chyba při načítání závodů:', error);
      races.value = []; // Vyprázdní data při chybě
      raceOptions.value = []; // Vyprázdní možnosti závodů
      selectedRace.value = null; // Vyprázdní vybraný závod při chybě
    }
  };

// Načtení seznamu roků a závodů při mountu komponenty
onMounted(() => {
  selectedYear.value = new Date().getFullYear(); // Automaticky nastaví aktuální rok
  fetchRaces(selectedYear.value); // Ihned načíst závody pro aktuální rok
});


// Načtení závodů při změně roku
watch(selectedYear, (newYear) => {
  if (newYear) {
    fetchRaces(newYear);
  }
});

</script>
  