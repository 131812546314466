<template>
  <v-dialog max-width="500px">
    <v-card>
      <v-form ref="formRef" @submit.prevent="submitRole">
        <v-card-title>
          {{
            (roleType === 'referee') ?
                (create ? 'Vytvořit rozhodčího' : 'Upravit rozhodčího')
                : (create ? 'Vytvořit trenéra' : 'Upravit trenéra')
          }}
        </v-card-title>

        <v-card-text>
          <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>

          <v-select
              v-model="roleData.lvl"
              :items="roleLvlOptions"
              item-title="text"
              item-value="value"
              label="Vyber třídu"
              :rules="[requiredRule]"
          ></v-select>
          <v-text-field
              v-model="roleData.valid_from"
              label="Platnost od"
              type="date"
              :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
              v-model="roleData.valid_to"
              label="Platnost do"
              type="date"
              :rules="[requiredRule]"
          ></v-text-field>
          <v-textarea
              v-model="roleData.description"
              maxlength="50"
              counter
              rows="1"
              auto-grow="true"
              label="Poznámka"
          ></v-textarea>

        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="$emit('close-dialog')">Zrušit</v-btn>
          <v-spacer />
          <v-btn text color="green" :disabled="isSubmitting" @click="submitRole">
            {{ create ? 'Vytvořit' : 'Uložit' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>


  <!--  <Snackbar-->
  <!--      v-model="successSnackbar"-->
  <!--      :message="successMessage"-->
  <!--  />-->


</template>


<script setup>
import {defineEmits, defineProps, ref, watch} from 'vue';
import apiClient from '/api.js';

const errorMessage = ref("");
const isSubmitting = ref(false);

const props = defineProps({
  dialog: Boolean,
  roleType: String,
  create: Boolean,
  accountId: Number,
  role: Object
});

const emit = defineEmits(['close-dialog', 'role-saved']);

const formRef = ref(null); // Reference na formulář
const requiredRule = (value) => !!value || 'Toto pole je povinné.';

const roleLvlOptions = [
  {value: '1', text: 'I. třída'},
  {value: '2', text: 'II. třída'},
  {value: '3', text: 'III. třída'},
];

const roleData = ref({
  lvl: '',
  valid_from: null,
  valid_to: null,
  description: '',
});

// Nastavení prázdných dat
const resetRoleData = () => {
  roleData.value = {
    lvl: '',
    valid_from: null,
    valid_to: null,
    description: '',
  };
};


//Synchronizace při otevření dialogu
watch(() => props.dialog,
    () => {
      errorMessage.value = ""

      if (props.create) {
        resetRoleData(); // Resetujeme pole při vytvoření
      } else if (props.role) {
        roleData.value.lvl = props.role.lvl;
        roleData.value.valid_from = new Date(props.role.valid_from).toISOString().split('T')[0];
        roleData.value.valid_to = new Date(props.role.valid_to).toISOString().split('T')[0];
        roleData.value.description = props.role.description;
      }
    }
);

// Sledujeme změny platnosti od a typu role
watch(
    () => roleData?.value?.valid_from,
    (newValidFrom) => {
      if (newValidFrom != null) {
        const yearsToAdd = props.roleType === 'coach' ? 3 : 5;

        // Vždy aktualizujeme valid_to při změně valid_from
        const validFromDate = new Date(newValidFrom);
        const validToDate = new Date(validFromDate);
        validToDate.setFullYear(validFromDate.getFullYear() + yearsToAdd);
        // odečteme od konečného data -1 den
        validToDate.setDate(validToDate.getDate() - 1);

        // Převod zpět na `yyyy-mm-dd`
        roleData.value.valid_to = validToDate.toISOString().split('T')[0];
      }
    }
);

const validateForm = () => {

  // Validace formuláře, jestli jsou vyplněná povinná pole
  if (formRef.value && !formRef.value.validate()) {
    return false;
  }

  return !!props.roleType &&
      !!props.accountId &&
      !!roleData.value.lvl &&
      !!roleData.value.valid_from &&
      !!roleData.value.valid_to;
};

// Odeslání formuláře
const submitRole = async () => {
  if (validateForm()) {
    try {
      const payload = {
        role_type: props.roleType,
        ...roleData.value,
      };

      if (props.create) {
        await apiClient.post(`account/role/?account_id=${props.accountId}`, payload);
      } else {
        await apiClient.put(`account/role/?role_id=${props.role.role_id}`, payload);
      }

      emit('role-saved');
      errorMessage.value = ""
      //  successSnackbar.value = true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail || 'Neznámá chyba.';

        if (status === 406 || status === 409) {
          errorMessage.value = detail;
        } else {
          console.error(`Neočekávaná chyba: ${status}`, detail);
          errorMessage.value = "Data se nepodařilo odeslat";
        }
      } else {
        console.error('Chyba při komunikaci s API:', error.message);
        errorMessage.value = "Data se nepodařilo odeslat";
      }
    }
  }
};

</script>


<style scoped>


</style>