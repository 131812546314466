<template>
  <div>
    <div ref="mapContainer" class="map"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import L from "leaflet";
import apiClient from "/api.js";
import "leaflet/dist/leaflet.css";

const props = defineProps({
  isOrganizer: Boolean, // Oprávnění z rodičovské komponenty
});

const API_KEY = "ve7dHe0hCMOIfqLdj4UbT-dC06RqAUKeEs9-lk6Q1bA";
const route = useRoute();
const eventId = ref(null);
const mapContainer = ref(null);
let map = null;
const markers = ref([]);

const DEFAULT_CENTER = [49.2450728, 16.6650531]; // Výchozí souřadnice
const DEFAULT_ZOOM = 11; // Výchozí zoom

// Přizpůsobená ikona markeru
const markerIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.9.2/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

onMounted(async () => {
  if (route.params.id) {
    eventId.value = parseInt(route.params.id);
  } else {
    console.error("Chyba: eventId není v URL!");
    return;
  }

  if (!mapContainer.value) return;

  // Inicializace Leaflet mapy
  map = L.map(mapContainer.value).setView(DEFAULT_CENTER, DEFAULT_ZOOM);
   
  // Původní turistická bez možnosti přepínat mezi vrstvama
  // L.tileLayer("https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=ve7dHe0hCMOIfqLdj4UbT-dC06RqAUKeEs9-lk6Q1bA", {attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',}).addTo(map);

  // **2. Definice mapových vrstev**
  const tileLayers = {
    "Základní": L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
      minZoom: 0,
      maxZoom: 19,
      // attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
    }),
    "Turistická": L.tileLayer(`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
      minZoom: 0,
      maxZoom: 19,
      // attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
    }),
    "Zimní": L.tileLayer(`https://api.mapy.cz/v1/maptiles/winter/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
      minZoom: 0,
      maxZoom: 19,
      // attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
    }),
    "Letecká": L.tileLayer(`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
      minZoom: 0,
      maxZoom: 19,
      // attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
    }),
  };

  // **Zabráníme nechtěné interakci mimo mapu, aby se windows item nepřepínal na etapu**
  L.DomEvent.disableClickPropagation(mapContainer.value);
  L.DomEvent.disableScrollPropagation(mapContainer.value);

  // **3. Nastavení výchozí vrstvy (Outdoor - Turistická)**
  tileLayers["Turistická"].addTo(map);

  // **4. Přidání ovládání pro přepínání vrstev**
  L.control.layers(tileLayers).addTo(map);

  await loadMarkers();

  if (props.isOrganizer) {
    map.on("click", async (e) => {
      const description = prompt("Zadejte popis bodu:");
      if (!description) return;
      await addMarker(e.latlng.lat, e.latlng.lng, description);
    });
  }
});

// Načtení markerů s popupem u jednoho. Nemůže se zobrazit vše najednou, to tento popup neumožňuje
const loadMarkers = async () => {
  try {
    if (!eventId.value) {
      console.error("Chyba: eventId není definován!");
      return;
    }

    const response = await apiClient.get(`event/map_marker/?event_id=${eventId.value}`);
    markers.value = [];

    if (response.data.length > 0) {
      const firstMarker = response.data[0];
      map.setView([firstMarker.lat, firstMarker.lng], 12);
    } else {
      map.setView(DEFAULT_CENTER, DEFAULT_ZOOM);
    }

    const markerGroup = L.layerGroup(); // Skupina pro všechny popupy

    response.data.forEach(({ id, lat, lng, description }) => {
      let popupContent = `
  <b>
    <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" 
      target="_blank"
      style="color: #0078D7;"
      onmouseover="this.style.textDecoration='underline';"
      onmouseout="this.style.textDecoration='none';"
    >
     ${description} 
    </a>
  </b>
`;
{/* <i class="mdi mdi-map-marker-path" style="font-size: 18px;"></i> */}


      if (props.isOrganizer) {
        popupContent += `
          <button class="delete-btn" data-id="${id}" 
            style="background:transparent; border:none; cursor:pointer; font-size: 18px; color: red; margin-left: 10px; ">
            <i class="mdi mdi-delete"></i>
          </button>
        `;
      }
      const marker = L.marker([lat, lng], { icon: markerIcon }).addTo(map);
      const popup = L.popup({ autoClose: false, closeOnClick: false, closeButton: false, maxWidth: 150, className: "custom-popup" }) // Zabrání zavření popupu po kliknutí
        .setLatLng([lat, lng])
        .setContent(popupContent);

      marker.bindPopup(popup);
      markerGroup.addLayer(popup); // Přidáme popup do skupiny

      marker._icon.dataset.id = id;

      if (props.isOrganizer) {
        marker.on("popupopen", (e) => {
          const deleteBtn = e.popup._contentNode.querySelector(".delete-btn");
          if (deleteBtn) {
            deleteBtn.addEventListener("click", (event) => {
              event.stopPropagation();
              deleteMarker(id);
            });
          }
        });
      }

      markers.value.push({ id, lat, lng, marker });
    });

    map.addLayer(markerGroup); // Přidáme celou skupinu popupů do mapy

      // přidání loga mapy.cz

    // const LogoControl = L.Control.extend({
    // options: {
    //   position: 'bottomleft',
    // },

  //   onAdd: function () {
  //     const container = L.DomUtil.create('div');
  //     const link = L.DomUtil.create('a', '', container);

  //     link.setAttribute('href', 'http://mapy.cz/');
  //     link.setAttribute('target', '_blank');
  //     link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
  //     L.DomEvent.disableClickPropagation(link);

  //     return container;
  //   },
  // });

  // // finally we add our LogoControl to the map
  // new LogoControl().addTo(map);

    } catch (error) {
      console.error("Chyba při načítání markerů:", error);
    }
  };


// Přidání markeru do API a zobrazení na mapě
const addMarker = async (lat, lng, description) => {
  try {
    if (!eventId.value) {
      console.error("Chyba: eventId není definován!");
      return;
    }

    await apiClient.post(`event/map_marker/?event_id=${eventId.value}`, {
      lat,
      lng,
      description,
    });

    await loadMarkers();

  } catch (error) {
    console.error("Chyba:", error);
  }
};

// Smazání markeru z API a mapy
const deleteMarker = async (markerId) => {
  try {
    if (!markerId) {
      console.error("Chyba: markerId není definován!");
      return;
    }

    await apiClient.delete(`event/map_marker/?marker_id=${markerId}`);

    markers.value = markers.value.filter((m) => m.id !== markerId);

    map.eachLayer((layer) => {
      if (layer instanceof L.Marker && layer._icon.dataset.id == markerId) {
        map.removeLayer(layer);
      }
    });

    console.log("Marker smazán:", markerId);

    // Pokud nejsou žádné markery po smazání, vrátíme výchozí pohled
    if (markers.value.length === 0) {
      map.setView(DEFAULT_CENTER, DEFAULT_ZOOM);
    }
  } catch (error) {
    console.error("Chyba při mazání markeru:", error);
  }
};

// Sledování změn v route a aktualizace eventId
watch(
  () => route.params.id,
  (newId) => {
    eventId.value = parseInt(newId);
    loadMarkers();
  }
);
</script>

<style scoped>
.map {
  width: 100%;
  height: 330px;
  border:1px solid grey
}

.delete-btn {
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}


</style>
