<template>
  <v-select
    :items="availableYears"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    density="comfortable"
    label="Vyber rok"
    hide-details="true"
  ></v-select>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';

const props = defineProps({
  modelValue: Number,
});

const emit = defineEmits(['update:modelValue']);

const availableYears = ref([]);

const initializeYears = () => {
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  availableYears.value = Array.from({ length: currentYear - startYear + 2 }, (_, i) => startYear + i);
  if (!props.modelValue) {
    emit('update:modelValue', currentYear);
  }
};

onMounted(() => {
  initializeYears();
});
</script>