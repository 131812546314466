// importování funkce, která vytváří z YYYY MM DD formátu správný formát používaný u nás

import { format,parseISO } from 'date-fns';


export default {
  install(app) {

    app.config.globalProperties.$formatTime = function (dateStr) {
      return format(new Date(dateStr), 'HH:mm');
    };

    // app.config.globalProperties.$formatDate = function (dateStr) {
    //   return format(new Date(dateStr), 'dd.MM.yyyy');
    // };

    app.config.globalProperties.$formatDate = function (dateStr) {
      if (!dateStr) return 'Neplatné datum';
      const parsedDate = parseISO(dateStr);
      if (isNaN(parsedDate)) return 'Neplatné datum';
      return format(parsedDate, 'dd.MM.yyyy');
    };

    app.config.globalProperties.$formatShortDate = function (dateStr) {
      if (!dateStr) return 'Neplatné datum';
      const parsedDate = parseISO(dateStr);
      if (isNaN(parsedDate)) return 'Neplatné datum';
      return format(parsedDate, 'd. M.');
    };

    
    app.config.globalProperties.$formatDateTime = function (dateTimeStr) {
      if (!dateTimeStr) return 'Datum a čas není známý';
      const cleanedDateTimeStr = dateTimeStr.replace(/\.\d+/, ''); // Odstranění mikrosekund
      return format(parseISO(cleanedDateTimeStr), 'dd.MM.yyyy HH:mm:ss');
    };


    app.config.globalProperties.$formatTimeSec = function (dateStr) {
      if (!dateStr) return 'N/A';  // Přidání kontroly pro prázdný nebo neplatný čas
    
      const date = new Date(dateStr);
      
      // Kontrola, zda je čas platný
      if (isNaN(date.getTime())) {
        console.error('Neplatný časový formát:', dateStr);
        return 'N/A';
      }
    
      const seconds = date.getSeconds();
      if (seconds !== 0) {
        return format(date, 'HH:mm:ss'); // Zobrazení včetně sekund
      } else {
        return format(date, 'HH:mm'); // Zobrazení bez sekund
      }
    };

    // při last_activity jsem musel upravit milisekundy a používám kod výše
    // app.config.globalProperties.$formatDateTime = function (dateTimeStr) {
    //   return format(new Date(dateTimeStr), 'dd.MM.yyyy HH:mm:ss');
    // };
  }
};